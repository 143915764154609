import { CountUp } from 'countup.js'
import ScrollReveal from 'scrollreveal'

var counter = document.querySelectorAll('.counter'),
    counterContainer = document.querySelectorAll('.counter-container')


document.addEventListener("DOMContentLoaded", function () {
    ScrollReveal().reveal('.counter-container', {
        afterReveal: (el) => {
            counter.forEach((obj, index) => {
                var endValue = Number(parseInt((obj.dataset.count.replace(/,/g, "")))),
                    startValue = (endValue >= 1000) ? (endValue - 100) : 0

                obj.style.opacity = '1'
                el.querySelectorAll('.counter-content').forEach((obj, index) => {
                    obj.style.opacity = '1'
                })
                
                const countup = new CountUp(obj, endValue, {startVal: startValue, duration: 10, useEasing: false})
                countup.start()
            })
        },
        viewFactor: 0.2,
        easing: 'ease-in' 
    })
})