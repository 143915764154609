import customSelect from 'custom-select'

const select = document.querySelectorAll('.properties select')

select.forEach((obj, index) => {
    customSelect(obj, {
        containerClass: 'select-container',
        openerClass: 'select-opener',
        panelClass: 'select-panel',
        optionClass: 'select-option'
    })
})

