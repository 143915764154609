import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

document.addEventListener("DOMContentLoaded", function () {
    var fullWidthSliders = document.querySelectorAll('.full-width-slider')

    fullWidthSliders.forEach((obj, index) => {
        const fullWidthSLider = new Swiper(obj.querySelector('.slider-container'), {
            direction: 'horizontal',
            loop: true,
            effect: 'fade',
            fadeEffect: {
                crossFade: false
            },
            speed: 500,
            autoplay: {
                delay: 3000
            },
            pagination: {
                el: obj.querySelector('.slider-pagination'),
                type: 'fraction',
            },
            navigation: {
                nextEl: obj.querySelector('.next'),
                prevEl: obj.querySelector('.prev')
            },
        })
    })
})
